<template>
  <div>
    <div v-show="dataModalShow" ref="app" @click.self="setFinished"  class="tw-fixed tw-z-10 tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-bg-gray-600/20 tw-flex tw-items-center tw-justify-center">
      <div class="tw-bg-white tw-p-5 tw-rounded-lg tw-mx-8 tw-max-w-5xl tw-max-h-[80vh] tw-overflow-auto tw-space-y-8">
        <div>
          <div class="tw-w-full tw-text-center tw-text-2xl tw-pt-2">Möchtest du deinem Warenkorb eine Grußkarte hinzufügen?</div>
          <div class="tw-w-full tw-text-center tw-text-lg">Soll noch eine Geschenkskarte hinzugefügt werden?</div>
        </div>

        <div class="tw-flex tw-gap-5 gap-5">
          <div class="tw-flex tw-gap-2 tw-items-center">
            <span>Kategorie:</span>
            <select v-model="dataFilteringCategory">
              <option value="">Alle</option>
              <option
                v-for="cat in computedFilteredCategories"
                :key="'cat_'+cat"
                :value="cat"
                >{{cat}}</option>
            </select>
          </div>
          <button class="button" @click="setFinished">Kartenauswahl überspringen</button>
        </div>

        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
          <component-product
            v-for="(product,index) in computedFilteredProducts"
            :product="product"
            :key="'product_'+index"
            @done="individualizeProduct"
            />
        </div>

        <p class="tw-text-gray-500">* Alle angegebenen Preise sind Endpreise zzgl. Versand. Aufgrund des Kleinunternehmerstatus gemäß § 19 UStG erheben wir keine Umsatzsteuer und weisen diese folglich auch nicht aus.</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import axios from 'axios';
import jQuery from 'jquery';
import ComponentProduct from './components/ComponentProduct.vue';
import mockProducts from './mock/products';

const app = ref<HTMLElement | null>(null);
const rootProps = ref<object>({});

const dataModalShow = ref<boolean>(false);
const dataProductList = ref<object[]>([]);
const dataBasketId = ref<number|null>(null);
const dataIsVariableProduct = ref<boolean>(false);
const dataFilteringCategory = ref<string>('');
const dataCategories = ref<string[]>([]);
const dataIsAjax = ref<boolean>(false);

const addBasketToCart = (callback) => {
  console.log('Is variant', dataIsVariableProduct.value)

  if (dataIsVariableProduct.value) {
    dataBasketId.value = jQuery('.variation_id').first().val()
  }

  if (!dataBasketId.value) return;

  if (dataIsVariableProduct.value) {
    jQuery.post(`/?add-to-cart=${dataBasketId.value}`, jQuery('form.variations_form').serialize(), (response) => {
      console.log('Success:', response)
      callback()
    });
  } else {
    jQuery.ajax({
      url: `/?add-to-cart=${dataBasketId.value}`,
      type: 'GET',
      success: function (ajaxSuccess) {
        console.log('Success:', ajaxSuccess)
        callback()
      },
      error: function (error) {
        console.error('Error:', error);
      },
    });
  }
}


onMounted(() => {
  if (!app.value) return;
  if (document.title === 'vue-blax-billet-customizer-app') dataModalShow.value = true;

  rootProps.value = jQuery('#blax-billet-individualizer-app').data();

  dataProductList.value = (rootProps.value?.products)
    ? rootProps.value?.products
    : mockProducts

  dataCategories.value = (rootProps.value?.categories)
    ? rootProps.value?.categories
    : ['Geburtstag', 'Hochzeit', 'Weihnachten']

  window.addEventListener('blax_openindividualizer', (event: CustomEvent) => {
    console.log('Event:', event.detail);

    dataIsAjax.value = event.detail?.ajax ?? false;
    dataBasketId.value = event.detail.basket_id;
    dataModalShow.value = true;
    dataIsVariableProduct.value = event.detail?.variable ?? false;
  });
});


const setFinished = () => {
  console.log('Set finished')
  dataModalShow.value = !dataModalShow.value

  const dispatchEvent = (basketAdded = false) => window.dispatchEvent(new CustomEvent('blax_personalization_finished', {
    detail: {
      basket_id: dataBasketId.value,
      basketAdded,
    },
  }));

  if (dataIsAjax.value) {
    addBasketToCart(() => {
      dispatchEvent(true)
    })
  } else {
    dispatchEvent()
  }
}

const individualizeProduct = (input: any) => {
  const {
    productId, text, font, withoutCustomization,
  } = input;

  const data = {
    text: text,
    font: font,
    basketId: dataBasketId.value,
    billetId: productId,
    withoutCustomization: withoutCustomization ?? false,
  };

  if (data.withoutCustomization) {
    jQuery.ajax({
      url: `/?add-to-cart=${data.billetId}`,
      type: 'GET',
      success: setFinished,
      error: () => {
        console.error('Error:', error);
      },
    });
  } else {
    axios.post('/wp-json/blax/v1/product-individualizer', data, {
      headers: {
        'X-WP-Nonce': (window?.wpApiSettings?.nonce ?? 'none'),
      },
    })
      .then(async (response) => {
        jQuery.ajax({
          url: `/?add-to-cart=${response.data.product_id}`,
          type: 'GET',
          success: setFinished,
          error: () => {
            console.error('Error:', error);
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

const computedFilteredCategories = computed(() => {
  if (!dataCategories?.value) return [];

  // if is object and not array turn to array
  if (typeof dataCategories?.value === 'object' && !Array.isArray(dataCategories?.value)) {
    return Object.values(dataCategories?.value);
  }

  console.log('Categories:', dataCategories?.value)

  // only return categories which have products
  return dataCategories?.value?.filter((cat) => dataProductList?.value?.some((product) => product.category?.includes(cat)));
})

const computedFilteredProducts = computed(() => {
  if (!dataProductList.value) return [];

  return dataProductList.value.filter((product) => {
    if (!dataFilteringCategory.value) return true;

    return product.category?.includes(dataFilteringCategory.value);
  });
})
</script>

