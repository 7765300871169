/* eslint-disable */
export default [
  {
    "id": 497,
    "name": "Est quia quisquam ea",
    "price": "<span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">$</span>5.00</bdi></span>",
    "image": "https://wordpress.localhost/wp-content/uploads/2024/05/04cfc277-3383-3466-8494-ecab9f533f3e.jpg",
    "category": 'Geburtstag',
  },
  {
    "id": 503,
    "name": "Ab dolorem veritatis ea deleniti voluptatem sed",
    "price": "<span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">$</span>2.00</bdi></span>",
    "image": "https://wordpress.localhost/wp-content/uploads/2024/05/5893c691-3cf3-34eb-ac96-68912028939d.jpg",
    "category": 'Hochzeit',
  },
  {
    "id": 499,
    "name": "Nulla molestiae incidunt ab",
    "price": "<span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">$</span>23.00</bdi></span>",
    "image": "https://wordpress.localhost/wp-content/uploads/2024/05/d4f330d7-3e47-310b-b53c-6c3aa8a07ab6.jpg",
    "category": 'Weihnachten',
  },
  {
    "id": 501,
    "name": "Maxime voluptates enim commodi est ea",
    "price": "<span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">$</span>15.00</bdi></span>",
    "image": "https://wordpress.localhost/wp-content/uploads/2024/05/6d6e8cc8-30c7-3b5b-b911-fc2dd7b647a5.jpg",
    "category": 'Geburtstag',
  },
  {
    "id": 506,
    "name": "Quis quod fugiat rem",
    "price": "<span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">$</span>8.54</bdi></span>",
    "image": "https://wordpress.localhost/wp-content/uploads/2024/05/2719faf4-d5a8-3f75-8728-fe022098ae4a.jpg"
  }
]
