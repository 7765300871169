<template>
  <div class="tw-overflow-auto tw-space-y-2 tw-flex tw-flex-col tw-shadow-md tw-shadow-gray-500/40 tw-rounded">
    <img :src="product.image" alt="product.name" class="tw-w-full tw-h-auto tw-object-cover">

    <div class="tw-px-2 tw-space-y-2 tw-h-full">
      <div v-html="product.name"></div>
      <span v-html="product.price"/> *

    </div>

    <div v-if="!isPersonalizing" class="tw-p-2 tw-space-y-1">
      <button @click="isPersonalizing = !isPersonalizing" class="button tw-w-full tw-p-2">Personalisieren</button>
      <button
        @click="$emit('done', {productId: product?.id, text: '', font: '', withoutCustomization: true})"
        class="button product_type_simple add_to_cart_button ajax_add_to_cart tw-w-full tw-text-center"
        rel="nofollow"
        style="white-space: pre-wrap;"
        >
        Ohne Text in den Warenkorb
      </button>
    </div>
    <div v-else-if="!createdIndividualCopy" class="tw-p-2 tw-space-y-1">
      <div class="tw-space-y-0">
        <textarea
          v-model="individualizedText"
          :class="selectedFont"
          maxlength="270"
          class="tw-w-full tw-border tw-p-1"
          placeholder="The quick brown fox jumps over the lazy dog"
          ></textarea>

        <small class="tw-float-end">{{ (individualizedText?.length ?? 0) }}/270</small>
      </div>

      <form>
        <div
          v-for="(font, fontClass) in availableFonts"
          :key="'id_'+fontClass"
          @click.self="selectedFont = fontClass"
          class="tw-cursor-pointer"
          :class="fontClass"
          >
          <input
            v-model="selectedFont"
            type="radio"
            :value="fontClass"
            />
          {{font}}
        </div>
      </form>

      <button class="button tw-w-full" @click="isPersonalizing = !isPersonalizing">
        Abbrechen
      </button>
      <button class="button tw-w-full" @click="$emit('done', {productId: product?.id, text: individualizedText, font: selectedFont})">
        Fertigstellen
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  product: Object,
})

const individualizedText = ref<string>('');
const isPersonalizing = ref<boolean>(false);
const selectedFont = ref<string>('');
const availableFonts = ref<object>({
  '':'Handgeschrieben (+5 €)',
  'font-dancing-script': 'Dancing Script',
  'font-great-vibes': 'Great Vibes',
  'font-josefin-sans': 'Josefin Sans',
  'font-maven-pro': 'Maven Pro',
  'font-quattrocento-sans': 'Quattrocento Sans',
})
</script>

<style scoped>
.button{
  @apply tw-text-center tw-inline-block tw-border;
}
</style>
